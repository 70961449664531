export const planTypes = {
  WITBOOKER_STARTER: "witbookerStarter",
  WITBOOKER: "witbooker",
  WITBOOKER_PLUS: "witbookerPlus",
  EMPTY: "empty",
};

export const rates = {
  EMPTY: "empty",
  ALL: "all",
  FLAT: "flat",
  MIXED: "mixed",
  VARIABLE: "variable",
};

export const planTypesAllowedRates = {
  [planTypes.WITBOOKER_STARTER]: [rates.VARIABLE],
  [planTypes.WITBOOKER]: [rates.FLAT, rates.MIXED],
  [planTypes.WITBOOKER_PLUS]: [rates.FLAT, rates.MIXED],
  [planTypes.EMPTY]: [],
};

export const units = {
  S: "S",
  M: "M",
  L: "L",
  XL: "XL",
  XXL: "XXL",
};

export const RATE_EMPTY = "rateEmpty";
export const UNIT_EMPTY = "unitEmpty";

export const rateFilters = [
  {
    key: RATE_EMPTY,
    name: "rateFiltersEmpty",
  },
  {
    key: "all",
    name: "rateFiltersAll",
  },
  {
    key: "flat",
    name: "rateFiltersFlat",
  },
  {
    key: "mixed",
    name: "rateFiltersMixed",
  },
  {
    key: "variable",
    name: "rateFiltersVariable",
  },
];

export const unitSizes = [
  {
    key: UNIT_EMPTY,
    name: "unitEmpty",
  },
  {
    key: "S",
    name: "unitSizeS",
  },
  {
    key: "M",
    name: "unitSizeM",
  },
  {
    key: "L",
    name: "unitSizeL",
  },
  {
    key: "XL",
    name: "unitSizeXL",
  },
  {
    key: "XXL",
    name: "unitSizeXXL",
  },
];

export const pricingValues = {
  [units.S]: {
    [planTypes.WITBOOKER]: {
      flatRate: {
        fixedFee: "156",
        annualWebSaleCap: "125.000",
      },
      mixedRate: {
        variable: "0.6",
        fixedFee: "112",
      },
    },
    [planTypes.WITBOOKER_PLUS]: {
      flatRate: {
        fixedFee: "206",
        annualWebSaleCap: "125.000",
      },
      mixedRate: {
        variable: "0.80",
        fixedFee: "148",
      },
    },
    [planTypes.WITBOOKER_STARTER]: {
      variableRate: {
        variable: "1.50",
        minimumPerMonth: "49",
      },
    },
  },
  [units.M]: {
    [planTypes.WITBOOKER]: {
      flatRate: {
        fixedFee: "242",
        annualWebSaleCap: "250.000",
      },
      mixedRate: {
        variable: "0.60",
        fixedFee: "154",
      },
    },
    [planTypes.WITBOOKER_PLUS]: {
      flatRate: {
        fixedFee: "323",
        annualWebSaleCap: "250.000",
      },
      mixedRate: {
        variable: "0.80",
        fixedFee: "206",
      },
    },
    [planTypes.WITBOOKER_STARTER]: {
      variableRate: {
        variable: "1.50",
        minimumPerMonth: "65",
      },
    },
  },
  [units.L]: {
    [planTypes.WITBOOKER]: {
      flatRate: {
        fixedFee: "362",
        annualWebSaleCap: "500.000",
      },
      mixedRate: {
        variable: "0.60",
        fixedFee: "186",
      },
    },
    [planTypes.WITBOOKER_PLUS]: {
      flatRate: {
        fixedFee: "482",
        annualWebSaleCap: "500.000",
      },
      mixedRate: {
        variable: "0.80",
        fixedFee: "249",
      },
    },
    [planTypes.WITBOOKER_STARTER]: {
      variableRate: {
        variable: "1.50",
        minimumPerMonth: "75",
      },
    },
  },
  [units.XL]: {
    [planTypes.WITBOOKER]: {
      flatRate: {
        fixedFee: "484",
        annualWebSaleCap: "750.000",
      },
      mixedRate: {
        variable: "0.60",
        fixedFee: "220",
      },
    },
    [planTypes.WITBOOKER_PLUS]: {
      flatRate: {
        fixedFee: "644",
        annualWebSaleCap: "750.000",
      },
      mixedRate: {
        variable: "0.80",
        fixedFee: "294",
      },
    },
    [planTypes.WITBOOKER_STARTER]: {
      variableRate: {
        variable: "1.50",
        minimumPerMonth: "89",
      },
    },
  },
  [units.XXL]: {
    [planTypes.WITBOOKER]: {
      flatRate: {
        fixedFee: "740",
        annualWebSaleCap: "1.200.000",
      },
      mixedRate: {
        variable: "0.60",
        fixedFee: "320",
      },
    },
    [planTypes.WITBOOKER_PLUS]: {
      flatRate: {
        fixedFee: "989",
        annualWebSaleCap: "1.200.000",
      },
      mixedRate: {
        variable: "0.80",
        fixedFee: "429",
      },
    },
    [planTypes.WITBOOKER_STARTER]: {
      variableRate: {
        variable: "1.50",
        minimumPerMonth: "131",
      },
    },
  },
};
